<template>
  <div>
    <v-row>
      <v-col lg="3" cols="sm" class="pb-2 align-self-center">
        <v-btn-toggle v-model="toggle_button" mandatory>
          <v-btn> Customers </v-btn>
          <v-btn> Employees </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2 align-self-center">
        <v-dialog ref="dialog" v-model="modal" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Pick a month"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker full-width v-model="dates" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                toggle_button
                  ? userSelectionChanged()
                  : projectSelectionChanged();
                modal = false;
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row v-if="!toggle_button" class="pb-4">
      <v-col lg="3" cols="sm" class="pb-2 align-self-center">
        <v-select
          class="mx-2"
          rounded
          dense
          :menu-props="{ offsetY: true }"
          v-if="user.role == 'Admin'"
          :items="customers"
          v-model="selectedCustomer"
          item-text="name"
          item-value="name"
          label="Customer"
          hide-details
          solo
        ></v-select>
      </v-col>
      <v-col lg="3" cols="sm" class="pb-2 align-self-center">
        <v-select
          class="mx-2"
          rounded
          dense
          :menu-props="{ offsetY: true }"
          v-if="user.role == 'Admin'"
          :items="projects"
          v-model="selectedProject"
          label="Project"
          hide-details
          solo
          @change="projectSelectionChanged"
        ></v-select>
      </v-col>
      <v-col lg="2" cols="sm" class="pb-2">
        <v-card shaped elevation="5">
          <v-row class="no-gutters">
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase">Total Employees</h5>
              <h1>{{ entries.length }}</h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="2" cols="sm" class="pb-2">
        <v-card shaped elevation="5">
          <v-row class="no-gutters">
            <div class="col pa-3 py-4 success--text">
              <h5 class="text-truncate text-uppercase">Total Hours</h5>
              <h1>{{ totalhrs }}</h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else class="pb-4">
      <v-col lg="3" cols="sm" class="pb-2 align-self-center">
        <v-select
          class="mx-2"
          rounded
          dense
          :menu-props="{ offsetY: true }"
          v-if="user.role == 'Admin'"
          :items="users"
          v-model="currentUser"
          item-text="name"
          item-value="id"
          label="User"
          hide-details
          solo
          @change="userSelectionChanged"
        ></v-select>
      </v-col>
      <v-col lg="2" cols="sm" class="pb-2">
        <v-card shaped elevation="5">
          <v-row class="no-gutters">
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase">Total Customers</h5>
              <h1>{{ totalCustomers }}</h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="2" cols="sm" class="pb-2">
        <v-card shaped elevation="5">
          <v-row class="no-gutters">
            <div class="col pa-3 py-4 primary--text">
              <h5 class="text-truncate text-uppercase">Total Projects</h5>
              <h1>{{ entries.length }}</h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
      <v-col lg="2" cols="sm" class="pb-2">
        <v-card shaped elevation="5">
          <v-row class="no-gutters">
            <div class="col pa-3 py-4 success--text">
              <h5 class="text-truncate text-uppercase">Total Hours</h5>
              <h1>{{ totalhrs }}</h1>
            </div>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        Statistics
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        @current-items="tablePageItems"
        :headers="toggle_button ? employeeHeaders : customerheaders"
        :items="entries"
        :search="search"
        :items-per-page="25"
        :footer-props="{
          'items-per-page-text': 'Rows Per Page',
          'items-per-page-options': [5, 10, 25],
        }"
      >
        <template v-slot:[`item.user`]="{ item }">
          {{ getUserName(item.user) }}
        </template>
        <template slot="body.append" v-if="entries.length">
          <tr>
            <td v-for="(header, i) in customerheaders" :key="i">
              <div v-if="header.value == 'hrs'">
                {{ "Total : " + pageTotalHrs }}
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
const API_URL = "https://timesheet.e-gits.com/api/timeentries/";
export default {
  mounted() {
    this.currentUser = this.$store.getters.user.id;
    this.userSelectionChanged();
    this.updateCustomers();
  },
  computed: {
    ...mapGetters(["user", "users"]),
    dateRangeText() {
      return this.dates.join(" to ");
    },
    dates: {
      get() {
        return this.$store.state.user.dates;
      },
      set(val) {
        return this.$store.dispatch("updateDates", val);
      },
    },
  },
  watch: {
    user() {
      this.currentUser = this.$store.getters.user.id;
      this.userSelectionChanged();
    },
    date() {
      this.userSelectionChanged();
    },
    selectedCustomer(val) {
      this.projects = this.customers.find((e) => e.name == val).projects;
      this.entries = [];
      this.totalhrs = 0;
      this.selectedProject = undefined;
      this.projectSelectionChanged();
    },
    toggle_button(val) {
      this.entries = [];
      this.totalhrs = 0;
      val ? this.userSelectionChanged() : this.projectSelectionChanged();
    },
  },
  data() {
    return {
      toggle_button: 0,
      // dates: [
      //   new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
      // .toISOString()
      // .substr(0, 10),
      //   new Date(Date.now() - 24 * 60 * 60 * 1000)
      // .toISOString()
      // .substr(0, 10),
      // ],
      menu: false,
      modal: false,
      currentUser: "",
      search: "",
      searchCustomer: "",
      selectedCustomer: undefined,
      selectedProject: undefined,
      customers: [],
      projects: [],
      pageTotalHrs: 0,
      employeeHeaders: [
        {
          text: "Customer",
          sortable: false,
          value: "customer",
        },
        { text: "Project", value: "project" },
        { text: "Hours", value: "hrs" },
      ],
      customerheaders: [
        {
          text: "Employee",
          sortable: false,
          value: "user",
        },
        { text: "Project", value: "project" },
        { text: "Hours", value: "hrs" },
      ],
      entries: [],
      totalEmployees: 0,
      totalCustomers: 0,
      totalhrs: 0,
    };
  },
  methods: {
    tablePageItems(e) {
      this.pageTotalHrs = e.reduce((hrs, val) => hrs + val.hrs, 0);
    },
    getUserName(id) {
      if (id) return this.users.find((e) => e.id == id).name;
    },
    userSelectionChanged() {
      if (this.currentUser) {
        const url =
          API_URL +
          "overview/" +
          this.currentUser +
          "/" +
          this.dates[0] +
          "/" +
          this.dates[1];
        this.$http
          .get(url)
          .then((response) => {
            this.entries = response.data;
            this.totalCustomers = [
              ...new Map(
                this.entries.map((item) => [item["customer"], item])
              ).values(),
            ].length;
            this.totalhrs = this.entries.length
              ? this.entries
                  .map((entry) => entry.hrs)
                  .reduce((hr, entry) => entry + hr)
              : 0;
          })
          .catch((error) => console.log(error));
      }
    },
    updateCustomers() {
      this.$http
        .get(API_URL + "projects/customers")
        .then((response) => {
          this.customers = response.data;
          if (this.customers.length > 0) {
            this.selectedCustomer = this.customers[0].name;
          } else {
            this.selectedCustomer = undefined;
            this.selectedProject = undefined;
            this.projects = [];
          }
        })
        .catch((error) => console.log(error));
    },
    projectSelectionChanged() {
      const url = this.selectedProject
        ? API_URL +
          "overview/customers/" +
          this.selectedCustomer +
          "/" +
          this.dates[0] +
          "/" +
          this.dates[1] +
          "/" +
          this.selectedProject
        : API_URL +
          "overview/customers/" +
          this.selectedCustomer +
          "/" +
          this.dates[0] +
          "/" +
          this.dates[1];
      this.$http
        .get(url)
        .then((response) => {
          this.entries = response.data;
          this.totalhrs = response.data.length
            ? response.data
                .map((entry) => entry.hrs)
                .reduce((hr, entry) => parseFloat(entry) + parseFloat(hr))
            : 0;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
