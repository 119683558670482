<template>
  <v-card class="align-self-center" elevation="5" rounded="xl" height="350">
    <v-card-text>
      <div class="pa-4">
        <v-chip-group active-class="primary--text" column>
          <v-chip v-for="tag in projects" :key="tag">
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "chip-group",
  props: {
    projects: Array,
  },
};
</script>