<template>
  <v-card elevation="5" rounded="xl">
    <v-row class="ma-4">
      <v-col class="d-flex flex-wrap ma-2" cols="auto">
        <div class="text-center">
          <v-avatar size="200">
            <img :src="cardUserImage" />
          </v-avatar>

          <v-row class="d-flex flex-wrap mt-2">
            <v-btn rounded color="green" @click="deletePhoto">Delete photo</v-btn>
            <v-btn class="ml-2" rounded color="primary" @click="changePhoto">Change photo</v-btn>
          </v-row>
        </div>
      </v-col>

      <v-col class="align-self-center">
        <h2 class="font-weight-medium" v-text="name"></h2>
        <div class="mt-2" v-text="email"></div>
        <v-divider class="mt-6 mx-1"></v-divider>
        <div class="d-flex justify-space-between caption mt-4">
          <div class="text-left">
            <div class="blue--text mb-2">Role</div>

            <h3 v-text="role"></h3>
          </div>

          <div class="text-center">
            <div class="blue--text mb-2">Status</div>

            <h3 v-text="status"></h3>
          </div>

          <div class="text-right">
            <div class="blue--text text-darken-1 mb-2">Account creation date</div>

            <h3 v-text="creationDate"></h3>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "user-card",
  props: {
    name: String,
    email: String,
    role: String,
    status: String,
    creationDate: String,
    cardUserImage: {
      type: String,
      default: require("@/assets/user_default.png"),
    },
  },
  methods: {
    changePhoto() {
      this.$emit("change-photo");
    },
    deletePhoto() {
      this.$emit("delete-photo");
    }
  },
  data() {
    return {};
  },
};
</script>
<style></style>