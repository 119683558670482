<template>
  <v-row justify="center">
    <v-col lg="3" class="ma-6 py-10">
      <v-row justify="center">
        <v-card elevation=5 rounded="xl" min-width="100%">
          <v-card-title class="justify-center">
            <v-img min-width="200px" max-width="200px" src="https://north.seco.com/fileadmin/user_upload/images/seco-logo.svg"></v-img>
          </v-card-title>
          <v-alert
                class="ma-2"
                outlined
                v-if="resetResponse"
                color="success"
                icon="mdi-alert-circle-outline"
                >{{ resetResponse }}</v-alert
              >
          <v-card-text v-if="!resetResponse">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-alert dense outlined v-if="error" color="error" icon="mdi-alert-circle-outline">{{error}}</v-alert>
              <v-text-field
                outlined
                rounded
                dense
                name="token"
                v-model="token"
                label="Reset Token"
                :rules="[rules.required]"
              ></v-text-field>

              <v-text-field
                outlined
                rounded
                dense
                name="password"
                label="Password"
                :rules="[rules.required, rules.password]"
                type="password"
                v-model="password"
              ></v-text-field>

              <v-text-field
                outlined
                rounded
                dense
                name="password"
                label="Confirm Password"
                :rules="[rules.required]"
                type="password"
                v-model="confirm_password"
                :error="!validateConfirmPassword()"
              ></v-text-field>
            </v-form>
            <v-btn block rounded outlined color="success" @click.prevent="submit()">Change Password</v-btn>
          </v-card-text>
          <v-card-actions class="justify-center">
            <router-link to="/login" class="card-link">Back to Login</router-link>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "signup",
  data: () => ({
    valid: true,
    password: "",
    confirm_password: "",
    resetResponse: false,
    rules: {
      required: (value) => !!value || "Required",
      password: (value) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
        return (
          pattern.test(value) ||
          "Min. 8 characters,one capital letter, a number and a special character (!@#$%^&*)"
        );
      },
    },
  }),
  props: [
    'token'
  ],
  computed: {
    ...mapGetters(["error"]),
  },
  mounted: {

  },
  methods: {
    ...mapActions(["resetpassword", "resetError"]),
    submit() {
      console.error("TTTTTTTTT", this.$route.params.token)
      if (this.$refs.form.validate()) {
        let user = {
          token: this.token,
          password: this.password,
          confirmPassword: this.confirm_password
        };
        this.resetpassword(user)
          .then((res) => {
            if (res.status === 200) {
              this.resetResponse = res.data.message
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    validateConfirmPassword() {
      return this.password === this.confirm_password;
    },
  },
  beforeRouteLeave: function (to, from, next) {
    this.resetError()
    next();
  },
};
</script>
