<template>
  <v-row justify="center">
    <v-col lg="3" class="ma-6 py-10">
      <v-row justify="center">
        <v-card elevation=5 rounded="xl" min-width="100%">
          <v-card-title class="justify-center">
            <v-img min-width="200px" max-width="200px" src="https://north.seco.com/fileadmin/user_upload/images/seco-logo.svg"></v-img>
          </v-card-title>
          <v-alert
                class="ma-2"
                outlined
                rounded="xl"
                v-if="resetResponse"
                color="success"
                icon="mdi-alert-circle-outline"
                >{{ resetResponse }}</v-alert
              >
          <v-card-text v-if="!resetResponse">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-alert dense outlined v-if="error" color="error" icon="mdi-alert-circle-outline">{{error}}</v-alert>
              <v-text-field
                outlined
                rounded
                dense
                name="email"
                v-model="email"
                label="Email address"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-form>
            <v-btn rounded block outlined color="success" @click.prevent="submit()">Reset</v-btn>
          </v-card-text>
          <v-card-actions class="justify-center">
            <router-link to="/login" class="card-link">Back to Login</router-link>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "signup",
  data: () => ({
    valid: true,
    email: "",
    resetResponse: false,
    rules: {
      required: (value) => !!value || "Required",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email must be valid";
      },
    },
  }),
  computed: {
    ...mapGetters(["error"]),
  },
  methods: {
    ...mapActions(["forgotpassword", "resetError"]),
    submit() {
      if (this.$refs.form.validate()) {
        let user = {
          email: this.email.toLowerCase(),
        };
        this.forgotpassword(user)
          .then((res) => {
            if (res.status === 200) {
              this.resetResponse = res.data.message
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  beforeRouteLeave: function (to, from, next) {
    this.resetError()
    next();
  },
};
</script>
