<template>
  <v-container fluid class="pa-0 pr-0" fill-height>
    <v-toolbar color="secondary" flat max-height="60px">
      <v-spacer></v-spacer>
      <h2 class="ml-4 mt-2">
        <v-toolbar-title>{{ "Project Settings" }}</v-toolbar-title>
      </h2>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-row class="fill-height">
      <v-col lg="6" sm="12">
        <v-card class="mx-4" height="97%" elevation="5" rounded="xl">
          <v-toolbar fixed>
            <v-text-field
              v-model="searchCustomer"
              prepend-inner-icon="mdi-magnify"
              label="Search Customer"
              single-line
              hide-details
              outlined
              rounded
              dense
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-tooltip bottom color="indigo lighten-1">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  class="text--primary"
                  v-bind="attrs"
                  v-on="on"
                  fab
                  small
                  @click="performAction = Action.ADD_CUSTOMER"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Customer</span>
            </v-tooltip>
          </v-toolbar>
          <v-list>
            <v-list-item-group v-model="selectedCustomer" color="primary">
              <v-list-item v-for="(item, i) in filteredCustomers" :key="i">
                <template v-slot:default="{ active }">
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="active">
                    <v-tooltip bottom color="error">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="confirmCustomerDeletion"
                        >
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Customer</span>
                    </v-tooltip>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col lg="6" sm="12">
        <v-card class="mr-4 ml-sm-4 ml-lg-0" height="97%" elevation="5" rounded="xl">
          <v-toolbar fixed>
            <v-text-field
              v-model="searchProject"
              prepend-inner-icon="mdi-magnify"
              label="Search Project"
              single-line
              hide-details
              outlined
              rounded
              dense
            ></v-text-field>
            <v-divider
              v-if="addProjectsEnabled"
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-tooltip
              v-if="addProjectsEnabled"
              bottom
              color="indigo lighten-1"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  class="text--primary"
                  v-bind="attrs"
                  v-on="on"
                  fab
                  small
                  @click="performAction = Action.ADD_PROJECT"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Project</span>
            </v-tooltip>
          </v-toolbar>
          <v-list>
            <v-list-item-group v-model="selectedProject" color="primary">
              <v-list-item v-for="(item, i) in filteredProjects" :key="i">
                <template v-slot:default="{ active }">
                  <v-list-item-icon>
                    <v-icon>mdi-briefcase</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="active">
                    <v-tooltip bottom color="error">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="confirmProjectDeletion"
                        >
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Project</span>
                    </v-tooltip>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- Create Dialog -->
    <v-dialog v-model="createDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{
            performAction === Action.ADD_CUSTOMER
              ? "Add Customer"
              : "Add Project"
          }}</span>
        </v-card-title>

        <v-card-text
          ><v-text-field
            autofocus
            class="ma-2"
            :label="
              performAction === Action.ADD_CUSTOMER
                ? 'Type Customer Name'
                : 'Type Project Name'
            "
            v-model="creationItemName"
            @keydown.enter="createSelection"
          ></v-text-field
        ></v-card-text>

        <v-card-actions class="justify-end">
          <v-btn outlined color="primary" text @click="clearSelection">
            Cancel
          </v-btn>
          <v-btn outlined color="primary" text @click="createSelection">
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{
            performAction === Action.DELETE_CUSTOMER
              ? "Delete Customer ?"
              : "Delete Project ?"
          }}</span>
        </v-card-title>
        <v-card-text
          >{{
            performAction === Action.DELETE_CUSTOMER
              ? this.customers[this.selectedCustomer].name
              : this.projects[this.selectedProject]
          }}
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn outlined color="primary" text @click="clearSelection">
            Cancel
          </v-btn>
          <v-btn outlined color="primary" text @click="deleteSelection">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
const Action = {
  ADD_CUSTOMER: 0,
  DELETE_CUSTOMER: 1,
  ADD_PROJECT: 2,
  DELETE_PROJECT: 3,
};
const API_URL = 'https://timesheet.e-gits.com/api/timeentries/';
export default {
  data() {
    return {
      API_URL,
      Action,
      searchCustomer: "",
      searchProject: "",
      selectedCustomer: -1,
      selectedProject: -1,
      customers: [],
      projects: [],
      creationItemName: "",
      createDialog: false,
      deleteDialog: false,
      addProjectsEnabled: false,
      performAction: null,
    };
  },

  watch: {
    selectedCustomer(val) {
      if (val > -1) {
        this.addProjectsEnabled = true;
        this.updateProjects(this.customers[val].name);
      } else {
        this.addProjectsEnabled = false;
      }
    },
    performAction(val) {
      if (val === Action.ADD_CUSTOMER || val === Action.ADD_PROJECT) {
        this.createDialog = true;
      } else if (
        val === Action.DELETE_CUSTOMER ||
        val === Action.DELETE_PROJECT
      ) {
        this.deleteDialog = true;
      } else {
        this.deleteDialog = false;
        this.createDialog = false;
      }
    },
  },

  computed: {
    filteredCustomers() {
      if (!this.searchCustomer) return this.customers;

      const search = this.searchCustomer.toLowerCase();

      return this.customers.filter((customer) => {
        const text = customer.name.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
    filteredProjects() {
      if (!this.searchProject) return this.projects;

      const search = this.searchProject.toLowerCase();

      return this.projects.filter((project) => {
        const text = project.toLowerCase();

        return text.indexOf(search) > -1;
      });
    },
  },

  mounted() {
    this.updateCustomers();
  },

  methods: {
    ...mapActions(["createAlert"]),
    confirmCustomerDeletion() {
      this.performAction = Action.DELETE_CUSTOMER;
    },
    confirmProjectDeletion() {
      this.performAction = Action.DELETE_PROJECT;
    },
    updateProjects(customerName) {
      this.$http
        .get(API_URL + "projects/customer/" + customerName)
        .then((response) => {
          this.projects = response.data[0].projects;
          this.selectedProject = -1;
        })
        .catch((error) => console.log(error));
    },
    updateCustomers() {
      this.$http
        .get(API_URL + "projects/customers")
        .then((response) => {
          this.customers = response.data;
          if (this.customers.length > 0) {
            this.selectedCustomer = 0;
          } else {
            this.selectedCustomer = -1;
            this.selectedProject = -1;
            this.projects = [];
          }
        })
        .catch((error) => console.log(error));
    },
    clearSelection() {
      this.performAction = null;
      this.creationItemName = "";
    },
    createSelection() {
      if (this.performAction === Action.ADD_CUSTOMER) {
        let customer = {
          name: this.creationItemName,
          projects: [],
        };
        this.$http
          .post(API_URL + "projects/customer", customer)
          .then((response) => {
            this.createAlert({ text: response.data.message, type: "success" });
            this.updateCustomers();
            this.clearSelection();
          })
          .catch((error) => {
            console.log(error);
            this.createAlert({
              text: error.response.data.message,
              type: "error",
            });
          });
      } else if (this.performAction === Action.ADD_PROJECT) {
        let customer = this.customers[this.selectedCustomer];
        customer.projects.push(this.creationItemName);

        this.$http
          .post(API_URL + "projects/addProject/" + customer.id, customer)
          .then((response) => {
            this.createAlert({ text: response.data.message, type: "success" });
            this.updateProjects(customer.name);
            this.clearSelection();
          })
          .catch((error) => {
            console.log(error);
            this.createAlert({
              text: error.response.data.message,
              type: "error",
            });
          });
      }
    },
    deleteSelection() {
      if (this.performAction === Action.DELETE_CUSTOMER) {
        this.$http
          .delete(
            API_URL +
              "projects/customer/" +
              this.customers[this.selectedCustomer].name
          )
          .then((response) => {
            this.createAlert({ text: response.data.message, type: "success" });
            this.updateCustomers();
            this.clearSelection();
          })
          .catch((error) => {
            console.log(error);
            this.createAlert({
              text: error.response.data.message,
              type: "error",
            });
          });
      } else if (this.performAction === Action.DELETE_PROJECT) {
        let customer = this.customers[this.selectedCustomer];
        customer.projects = customer.projects.filter(
          (item) => item !== this.projects[this.selectedProject]
        );

        this.$http
          .post(API_URL + "projects/addProject/" + customer.id, customer)
          .then((response) => {
            this.createAlert({ text: response.data.message, type: "success" });
            this.updateProjects(customer.name);
            this.clearSelection();
          })
          .catch((error) => {
            console.log(error);
            this.createAlert({
              text: error.response.data.message,
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
.v-list {
  max-height: 85vh;
  overflow-y: auto;
}
</style>