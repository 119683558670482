<template>
  <v-row justify="center">
    <v-col lg="3" class="ma-6 py-16">
      <v-row justify="center">
        <v-card elevation=5 rounded="xl" min-width="90%">
          <v-card-title class="justify-center">
            <v-img
              contain
              aspect-ratio="2"
              max-width="200px"
              src="https://north.seco.com/fileadmin/user_upload/images/seco-logo.svg"
            ></v-img>
          </v-card-title>
          <v-card-text class="text-center pa-2">Timesheet</v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-alert
                dense
                outlined
                v-if="error"
                color="error"
                icon="mdi-alert-circle-outline"
                >{{ error }}</v-alert
              >
              <v-text-field
                v-model="email"
                name="login"
                label="Email address"
                type="text"
                outlined
                dense
                rounded
                required
                :rules="emailRules"
              ></v-text-field>

              <v-text-field
                v-model="password"
                name="password"
                label="Password"
                type="password"
                outlined
                dense
                rounded
                required
                :rules="passwordRules"
              ></v-text-field>
              <v-btn
                block
                outlined
                color="success"
                dark
                rounded
                @click.prevent="loginUser()"
                >Login</v-btn
              >
            </v-card-text>
          </v-form>
          <v-card-actions class="justify-center">
          <router-link to="/forgotpassword" class="card-link">Forgot Password?</router-link>
          <!-- <v-spacer></v-spacer> -->
          <!-- <router-link to="/signup" disable class="card-link">ForgetPassword</router-link> -->
          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<style>
@import "../../assets/css/styles.css";
</style>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "login",
  data: () => ({
    valid: true,
    email: "",
    verifyEmail: "",
    emailRules: [
      //(v) => !!v || "Email is required",
      (v) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "E-mail must be valid",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length >= 8) || "Password must be more than 8 characters",
    ],
  }),
  methods: {
    ...mapActions(["login", "resetError"]),
    loginUser() {
      if (this.$refs.form.validate()) {
        let user = {
          email: this.email.toLowerCase(),
          password: this.password,
        };
        this.login(user)
          .then((res) => {
            if (res) {
              this.$router.push("/");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  computed: {
    ...mapGetters(["error"]),
  },
  beforeRouteLeave: function (to, from, next) {
    this.resetError();
    next();
  },
};
</script>
