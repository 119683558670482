<template>
  <v-row class="fill-height" dense>
    <v-col>
      <!-- Toolbar -->
      <v-toolbar color="secondary" flat max-height="60px">
        <v-spacer></v-spacer>
        <h2 class="ml-4 mt-2">
          <v-toolbar-title>{{ "User management" }}</v-toolbar-title>
        </h2>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar color="secondary" flat max-height="60px" v-if="users">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              :color="userDeletionEnabled ? 'red' : 'green'"
              @click="userDeletionEnabled = !userDeletionEnabled"
            >
              <v-icon color="white">{{
            (userDeletionEnabled ? "mdi-lock" : "mdi-lock-open")
          }}</v-icon>
            </v-btn>
          </template>
          <span>{{
            (userDeletionEnabled ? "Disable user deletion" : "Enable user deletion")
          }}</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-text-field
          justify="center"
          rounded
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          solo
          dense
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              rounded
              color="primary"
              @click="actionTriggered(Action.ADD)"
            >
              <v-icon color="white">mdi-account-plus</v-icon>
            </v-btn>
          </template>
          <span>Add User</span>
        </v-tooltip>
      </v-toolbar>
      <v-card class="ma-4" elevation="5" rounded="xl">
        <!-- User Table -->
        <v-data-table
          :search="search"
          :headers="headers"
          :items="users"
          :items-per-page="users.length"
          mobile-breakpoint="1400"
          hide-default-footer
        >
          <template v-slot:item.created="{ item }">
            {{ new Date(item.created).toUTCString() }}
          </template>
          <template v-slot:item.role="{ item }">
            <v-chip>{{ item.role }}</v-chip>
          </template>
          <template v-slot:item.enabled="{ item }">
            <v-simple-checkbox
              v-model="item.enabled"
              :color="item.enabled ? 'primary' : 'error'"
              :value="item.enabled"
              @change="enableUserAccount(item)"
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-row align="center" justify="end">
              <v-btn
                class="mr-2"
                rounded
                color="primary"
                @click="actionTriggered(Action.EDIT, item)"
                ><v-icon dark> mdi-pencil </v-icon></v-btn
              >
              <v-btn
                class="mr-2"
                rounded
                color="error"
                @click="actionTriggered(Action.DELETE, item)"
                :disabled="!userDeletionEnabled"
                ><v-icon dark> mdi-delete </v-icon></v-btn
              >
            </v-row>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
    <!-- Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="ma-2"
          >{{
            performAction === Action.ADD
              ? "Add New User"
              : performAction === Action.EDIT
              ? "Edit User"
              : "Confirm to delete user?"
          }}
        </v-card-title>
        <v-card-text v-if="performAction === Action.ADD || performAction === Action.EDIT">
          <v-form ref="form" v-model="addUser.valid" lazy-validation>
            <v-text-field
              outlined
              dense
              v-model="addUser.name"
              label="Name"
              :rules="[addUser.rules.required]"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="addUser.email"
              label="Email address"
              :rules="[addUser.rules.required, addUser.rules.email]"
            ></v-text-field>
            <v-text-field
              v-if="performAction === Action.ADD"
              outlined
              dense
              label="Password"
              :rules="[addUser.rules.required, addUser.rules.password]"
              type="password"
              v-model="addUser.password"
            ></v-text-field>
            <v-text-field
              v-if="performAction === Action.ADD"
              outlined
              dense
              label="Confirm Password"
              :rules="[addUser.rules.required]"
              type="password"
              v-model="addUser.confirm_password"
              :error="!validateConfirmPassword()"
            ></v-text-field>
            <v-select
              :items="['Admin', 'User']"
              label="Select Role"
              v-model="addUser.role"
              return-value
              hide-details
              :rules="[addUser.rules.required]"
              outlined
              dense
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-text v-if="performAction === Action.DELETE">
          <v-form ref="form" v-model="addUser.valid" lazy-validation>
            <v-chip>{{ this.selectedUser.email }}</v-chip>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn outlined color="primary" text @click="clearSelection">
            Cancel
          </v-btn>
          <v-btn
            outlined
            color="primary"
            @click="performAction === Action.DELETE ? deleteUser() : (performAction === Action.EDIT ? editUser() : createUser())"
          >
            {{ performAction === Action.DELETE ? "Delete" : "Submit" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
const Action = {
  ADD: "add",
  DELETE: "delete",
  EDIT: "edit",
};
export default {
  computed: {
    ...mapGetters(["users"]),
  },
  data() {
    return {
      Action,
      userDeletionEnabled: false,
      dialog: false,
      dialogStep: 1,
      editedItem: [],
      selectedUser: null,
      search: "",
      filterKeys: [],
      dynamicGroup: {
        operation: "$eq",
        type: "D",
      },
      headers: [
        { text: "Name", sortable: false, value: "name", width: "260" },
        { text: "Email", sortable: false, value: "email", width: "260" },
        {
          text: "Role",
          sortable: false,
          value: "role",
          width: "120",
          align: "center",
        },
        { text: "Created", sortable: false, value: "created" },
        {
          text: "Enabled",
          sortable: false,
          value: "enabled",
          width: "120",
          align: "center",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
          width: "160",
          align: "center",
        },
      ],
      performAction: null,
      // Add User
      addUser: {
        valid: false,
        name: "",
        email: "",
        password: "",
        role: "",
        confirm_password: "",
        verified: false,
        rules: {
          required: (value) => !!value || "Required",
          email: (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Email must be valid";
          },
          password: (value) => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
            return (
              pattern.test(value) ||
              "Min. 8 characters,one capital letter, a number and a special character (!@#$%^&*)"
            );
          },
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "create",
      "enableUser",
      "resetError",
      "delete",
      "createAlert",
      "edit",
      "getAllUsers",
    ]),
    clearSelection() {
      this.selectedUser = null;
      this.dialog = false;
      this.dialogStep = 1;
      this.performAction = null;
      this.dynamicGroup = {
        operation: "$eq",
        type: "D",
      };

      // Add User
      this.addUser.name = "";
      this.addUser.role = "";
      this.addUser.valid = true;
      this.addUser.email = "";
      this.addUser.password = "";
      this.addUser.confirm_password = "";
      this.resetError();
    },
    editUser() {
      if (this.$refs.form.validate()) {
        this.selectedUser.name = this.addUser.name;
        this.selectedUser.email = this.addUser.email;
        this.selectedUser.role = this.addUser.role;
        this.edit(this.selectedUser)
          .then((res) => {
            if (res.status === 200) {
              this.createAlert({ text: res.data.message, type: "success" });
              this.clearSelection();
              this.getAllUsers();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    createUser() {
      if (this.$refs.form.validate()) {
        let user = {
          name: this.addUser.name,
          email: this.addUser.email.toLowerCase(),
          password: this.addUser.password,
          confirmPassword: this.addUser.confirm_password,
          role: this.addUser.role,
        };
        this.create(user)
          .then((res) => {
            if (res.status === 200) {
              this.createAlert({ text: res.data.message, type: "success" });
              this.clearSelection();
              this.getAllUsers();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    enableUserAccount(user) {
      this.enableUser(user)
        .then((res) => {
          if (res.status === 200) {
            this.createAlert({ text: res.data.message, type: "success" });
            this.clearSelection();
            this.getAllUsers();
          }
        })
        .catch((err) => {
            console.log(err);
            this.createAlert({ text: 'Error while enabling user', type: "error" });
          });
    },
    deleteUser() {
      this.delete(this.selectedUser.id)
        .then((res) => {
          if (res.status === 200) {
            this.createAlert({ text: res.data.message, type: "success" });
            this.clearSelection();
            this.getAllUsers();
          }
        })
        .catch((err) => {
          console.log(err);
          this.createAlert({
            text: "Error while deleting user",
            type: "error",
          });
        });
    },
    validateConfirmPassword() {
      return this.addUser.password === this.addUser.confirm_password;
    },
    actionTriggered(action, user = null) {
      if (user) {
        this.selectedUser = user;
      }
      if (action === Action.EDIT) {
        this.addUser.name = user.name;
        this.addUser.email = user.email;
        this.addUser.role = user.role;
      }
      switch (action) {
        case Action.ADD:
          this.performAction = Action.ADD;
          break;
        case Action.EDIT:
          this.performAction = Action.EDIT;
          break;
        case Action.DELETE:
          this.performAction = Action.DELETE;
          break;
      }
      this.dialog = true;
    },
  },
  created() {
    this.getAllUsers();
  },
};
</script>
