<template>
  <v-col>
    <v-dialog v-model="uploadDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload photo</span>
        </v-card-title>

        <v-card-text>
          <v-file-input
            :rules="rules"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Pick an image"
            prepend-icon="mdi-camera"
            v-model="imageData"
          ></v-file-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            rounded
            color="primary"
            @click="uploadDialog = false"
            >Cancel</v-btn
          >
          <v-btn rounded color="primary" @click="onUpload">Upload</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <user-card
      :name="user.name"
      :email="user.email"
      :role="user.role"
      :status="status"
      :creationDate="creationDate"
      :cardUserImage="userImage"
      class="ma-4"
      @change-photo="uploadDialog = true"
      @delete-photo="deleteProfilePicture"
    >
    </user-card>

    <h2 class="ma-4 mt-6">Recent Entries</h2>
    <v-sheet class="ma-4" elevation="5" rounded="xl">
      <v-slide-group
        v-model="model"
        class="pa-4"
        active-class="success"
        show-arrows
      >
        <v-slide-item v-for="entry in recentEntries" :key="entry.id">
          <v-card
            color="secondary"
            class="ma-4"
            height="300"
            width="300"
            elevation="5"
          >
            <v-card-title>
              <v-avatar size="50">
                <img :src="userImage" />
              </v-avatar>
              <span class="ml-4 title font-weight-light">{{
                new Date(entry.date).toDateString()
              }}</span>
            </v-card-title>

            <v-card-text>
              <v-textarea
                class="text-subtitle-2 text-break font-weight-bold"
                no-resize
                flat
                solo
                background-color="secondary"
                readonly
                rows="4"
                :value="
                  entry.workDetails
                    ? entry.workDetails
                    : 'No entry on this date'
                "
              >
              </v-textarea>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <v-container fluid>
      <v-row no-gutters align="center">
        <v-col lg="4" sm="12">
          <h2 class="ml-1">Recent Projects</h2>
          <chip-group
            class="ml-1 mt-4 mr-sm-1 mr-lg-0"
            v-bind:projects="recentProjects"
          >
          </chip-group>
        </v-col>
        <v-col lg="8" sm="12">
          <h2 class="ml-lg-9 ml-sm-1 mt-lg-0 mt-6">Change Password</h2>
          <v-card
            class="ml-lg-9 ml-sm-1 mr-1 mt-4"
            elevation="5"
            rounded="xl"
            height="350"
          >
            <v-card-text>
              <v-form ref="form" v-model="changeUser.valid" lazy-validation>
                <v-text-field
                  class="mt-5"
                  outlined
                  dense
                  rounded
                  name="oldpassword"
                  v-model="changeUser.oldpassword"
                  label="Old password"
                  type="password"
                  :rules="[
                    changeUser.rules.required,
                    changeUser.rules.password,
                  ]"
                ></v-text-field>
                <v-text-field
                  outlined
                  rounded
                  dense
                  name="password"
                  label="New password"
                  :rules="[
                    changeUser.rules.required,
                    changeUser.rules.password,
                  ]"
                  type="password"
                  v-model="changeUser.password"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  rounded
                  name="password"
                  label="Confirm new password"
                  :rules="[changeUser.rules.required]"
                  type="password"
                  v-model="changeUser.confirm_password"
                  :error="!validateConfirmPassword()"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn rounded color="green" @click="clearPasswords">
                Cancel
              </v-btn>
              <v-btn
                class="mr-2"
                rounded
                color="primary"
                @click="updateUserPassword()"
              >
                Update Password
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-col>
</template>

<script>
import UserCard from "./components/UserCard";
import ChipGroup from "./components/ChipGroup";
import { mapActions, mapGetters } from "vuex";

const API_URL = 'https://timesheet.e-gits.com/api/timeentries/';
const GRIDFS_URL = 'https://timesheet.e-gits.com/gridfs/api/v0/';
export default {
  components: {
    UserCard,
    ChipGroup,
  },

  data: () => ({
    API_URL,
    GRIDFS_URL,
    model: null,
    uploadPercentage: 0,
    imageData: null,
    uploadDialog: false,
    recentEntries: [],
    recentProjects: [],
    changeUser: {
      valid: false,
      oldpassword: "",
      password: "",
      confirm_password: "",
      verified: false,
      rules: {
        required: (value) => !!value || "Required",
        password: (value) => {
          const pattern =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
          return (
            pattern.test(value) ||
            "Min. 8 characters,one capital letter, a number and a special character (!@#$%^&*)"
          );
        },
      },
    },
    rules: [
      (value) =>
        !value ||
        value.size < 10000000 ||
        "Image size should be less than 10 MB!",
    ],
  }),

  mounted() {
    this.updateRecentEntries();
    this.updateRecentProjects();
  },

  methods: {
    ...mapActions([
      "getProfileImage",
      "updatePassword",
      "resetError",
      "createAlert",
    ]),
    updateUserPassword() {
      if (this.$refs.form.validate()) {
        let passwordData = {
          oldpassword: this.changeUser.oldpassword,
          password: this.changeUser.password,
          confirmPassword: this.changeUser.confirm_password,
        };
        this.updatePassword(passwordData)
          .then((res) => {
            if (res) {
              this.createAlert({ text: res.data.message, type: "success" });
              this.clearPasswords();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    updateRecentProjects() {
      this.$http
        .get(API_URL + "recentProjects")
        .then((response) => {
          this.recentProjects = response.data.projectList;
          if (this.recentProjects.length == 0) {
            this.recentProjects.push("No recent projects found");
          }
        })
        .catch((error) => console.log(error));
    },
    validateConfirmPassword() {
      return this.changeUser.password === this.changeUser.confirm_password;
    },
    clearPasswords() {
      this.changeUser.valid = true;
      this.changeUser.oldpassword = "";
      this.changeUser.password = "";
      this.changeUser.confirm_password = "";
      this.$refs.form.resetValidation();
      this.resetError();
    },
    onUpload() {
      this.deleteAllPictures();
      let formData = new FormData();
      formData.append("userId", this.user.id);
      formData.append("userEmail", this.user.email);
      formData.append("file", this.imageData);
      this.$http
        .post(GRIDFS_URL + "images/upload", formData, {
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
        .then((response) => {
          this.createAlert({ text: response.data.message, type: "success" });
          this.getProfileImage();
          this.uploadDialog = false;
        })
        .catch((error) => {
          console.log(error);
          this.createAlert({
            text: error.response.data.message,
            type: "error",
          });
          this.uploadDialog = false;
        });
    },
    deleteAllPictures() {
      this.$http.post(GRIDFS_URL + "images/deleteAll").catch((error) => {
        console.log(error);
      });
    },
    deleteProfilePicture() {
      this.$http
        .post(GRIDFS_URL + "images/delete")
        .then((response) => {
          this.createAlert({ text: response.data.message, type: "success" });
          this.getProfileImage();
        })
        .catch((error) => {
          console.log(error);
          this.createAlert({
            text: error.response.data.message,
            type: "error",
          });
        });
    },
    updateRecentEntries() {
      this.$http
        .get(API_URL + "recentEntries")
        .then((response) => {
          this.recentEntries = response.data;
        })
        .catch((error) => console.log(error));
    },
  },

  computed: {
    ...mapGetters(["user", "userImage"]),
    status() {
      return this.$store.getters.user.enabled ? "Active" : "Disabled";
    },
    creationDate() {
      return new Date(this.$store.getters.user.created).toDateString();
    },
  },
};
</script>
